
@charset "UTF-8";

@font-face {
  font-family: "dripicons";
  src:url("../font/dripicons.eot");
  src:url("../font/dripicons.eot?#iefix") format("embedded-opentype"),
    url("../font/dripicons.ttf") format("truetype"),
    url("../font/dripicons.svg#dripicons") format("svg"),
    url("../font/dripicons.woff") format("woff");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "dripicons";
  content: attr(data-icon);
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none !important;
  speak: none;
  display: inline-block;
  text-decoration: none;
  width: 1em;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "dripicons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none !important;
  speak: none;
  display: inline-block;
  text-decoration: none;
  width: 1em;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
 
}


.icon-align-center:before {
  content: "\e000";
}

.icon-align-justify:before {
  content: "\e001";
}

.icon-align-left:before {
  content: "\e002";
}

.icon-align-right:before {
  content: "\e003";
}

.icon-arrow-down:before {
  content: "\e004";
}

.icon-arrow-left:before {
  content: "\e005";
}

.icon-arrow-thin-down:before {
  content: "\e006";
}

.icon-arrow-right:before {
  content: "\e007";
}

.icon-arrow-thin-left:before {
  content: "\e008";
}

.icon-arrow-thin-up:before {
  content: "\e009";
}

.icon-arrow-up:before {
  content: "\e010";
}

.icon-attachment:before {
  content: "\e011";
}

.icon-arrow-thin-right:before {
  content: "\e012";
}

.icon-code:before {
  content: "\e013";
}

.icon-cloud:before {
  content: "\e014";
}

.icon-chevron-right:before {
  content: "\e015";
}

.icon-chevron-up:before {
  content: "\e016";
}

.icon-chevron-down:before {
  content: "\e017";
}

.icon-chevron-left:before {
  content: "\e018";
}

.icon-camera:before {
  content: "\e019";
}

.icon-checkmark:before {
  content: "\e020";
}

.icon-calendar:before {
  content: "\e021";
}

.icon-clockwise:before {
  content: "\e022";
}

.icon-conversation:before {
  content: "\e023";
}

.icon-direction:before {
  content: "\e024";
}

.icon-cross:before {
  content: "\e025";
}

.icon-graph-line:before {
  content: "\e026";
}

.icon-gear:before {
  content: "\e027";
}

.icon-graph-bar:before {
  content: "\e028";
}

.icon-export:before {
  content: "\e029";
}

.icon-feed:before {
  content: "\e030";
}

.icon-folder:before {
  content: "\e031";
}

.icon-forward:before {
  content: "\e032";
}

.icon-folder-open:before {
  content: "\e033";
}

.icon-download:before {
  content: "\e034";
}

.icon-document-new:before {
  content: "\e035";
}

.icon-document-edit:before {
  content: "\e036";
}

.icon-document:before {
  content: "\e037";
}

.icon-gaming:before {
  content: "\e038";
}

.icon-graph-pie:before {
  content: "\e039";
}

.icon-heart:before {
  content: "\e040";
}

.icon-headset:before {
  content: "\e041";
}

.icon-help:before {
  content: "\e042";
}

.icon-information:before {
  content: "\e043";
}

.icon-loading:before {
  content: "\e044";
}

.icon-lock:before {
  content: "\e045";
}

.icon-location:before {
  content: "\e046";
}

.icon-lock-open:before {
  content: "\e047";
}

.icon-mail:before {
  content: "\e048";
}

.icon-map:before {
  content: "\e049";
}

.icon-media-loop:before {
  content: "\e050";
}

.icon-mobile-portrait:before {
  content: "\e051";
}

.icon-mobile-landscape:before {
  content: "\e052";
}

.icon-microphone:before {
  content: "\e053";
}

.icon-minus:before {
  content: "\e054";
}

.icon-message:before {
  content: "\e055";
}

.icon-menu:before {
  content: "\e056";
}

.icon-media-stop:before {
  content: "\e057";
}

.icon-media-shuffle:before {
  content: "\e058";
}

.icon-media-previous:before {
  content: "\e059";
}

.icon-media-play:before {
  content: "\e060";
}

.icon-media-next:before {
  content: "\e061";
}

.icon-media-pause:before {
  content: "\e062";
}

.icon-monitor:before {
  content: "\e063";
}

.icon-move:before {
  content: "\e064";
}

.icon-plus:before {
  content: "\e065";
}

.icon-phone:before {
  content: "\e066";
}

.icon-preview:before {
  content: "\e067";
}

.icon-print:before {
  content: "\e068";
}

.icon-media-record:before {
  content: "\e069";
}

.icon-music:before {
  content: "\e070";
}

.icon-home:before {
  content: "\e071";
}

.icon-question:before {
  content: "\e072";
}

.icon-reply:before {
  content: "\e073";
}

.icon-reply-all:before {
  content: "\e074";
}

.icon-return:before {
  content: "\e075";
}

.icon-retweet:before {
  content: "\e076";
}

.icon-search:before {
  content: "\e077";
}

.icon-view-thumb:before {
  content: "\e078";
}

.icon-view-list-large:before {
  content: "\e079";
}

.icon-view-list:before {
  content: "\e080";
}

.icon-upload:before {
  content: "\e081";
}

.icon-user-group:before {
  content: "\e082";
}

.icon-trash:before {
  content: "\e083";
}

.icon-user:before {
  content: "\e084";
}

.icon-thumbs-up:before {
  content: "\e085";
}

.icon-thumbs-down:before {
  content: "\e086";
}

.icon-tablet-portrait:before {
  content: "\e087";
}

.icon-tablet-landscape:before {
  content: "\e088";
}

.icon-tag:before {
  content: "\e089";
}

.icon-star:before {
  content: "\e090";
}

.icon-volume-full:before {
  content: "\e091";
}

.icon-volume-off:before {
  content: "\e092";
}

.icon-warning:before {
  content: "\e093";
}

.icon-window:before {
  content: "\e094";
}

